import React, { Fragment, useEffect } from 'react'
import { styled } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import EmptyTableMessage from '../../HOC/FunctionComponents/EmptyTableMessage';
import { Stack, Typography } from '@mui/material';

export const batchFields = [
    "id",
    { barcodes: ["id", "code"] }
];

export const shipmentFields = [
    {
        data: [
            "code",
            "recipientName",
            "recipientAddress",
            "totalAmount",
            "senderName",
            "notes",
            {
                recipientZone: ["name"],
            },
            {
                recipientSubzone: ["name"],
            },
        ],
    },
];

const PREFIX = "SHIPMENT_STICKY_PRINT";

export const classes = {
    printSticky: `${PREFIX}-printSticky`,
    stickyCode: `${PREFIX}-stickyCode`,
    stickyRecipientName: `${PREFIX}-stickyRecipientName`,
    stickyDestination: `${PREFIX}-stickyDestination`,
    stickyRecipientAddress: `${PREFIX}-stickyRecipientAddress`,
    centerContent: `${PREFIX}-centerContent`,
    barcode: `${PREFIX}-barcode`,
    stickyFont: `${PREFIX}-stickyFont`,
};
export const Root = styled("div")(({ theme }) => ({
    [`& .${classes.printSticky}`]: {
         
        pageBreakInside: "avoid",
        maxWidth: "5cm",
        maxHeight: "4cm",
        overflow: "hidden",
        fontSize: 11,
        color: "black",
        height: "100%",
        lineHeight: 1.4
    },

    [`& .${classes.stickyCode}`]: {
        height: "calc(100% * (1/4))",
        overflow: "hidden",
        fontSize: "30px !important",
    },

    [`& .${classes.stickyRecipientName}`]: {
        height: "calc(100% * (0.75/4))",
        overflow: "hidden",
        whiteSpace: "pre",
    },

    [`& .${classes.stickyDestination}`]: {
        height: "calc(100% * (0.75/4))",
        overflow: "hidden",
        whiteSpace: "pre",
    },

    [`& .${classes.stickyRecipientAddress}`]: {
        height: "calc(100% * (1.5/4))",
        overflow: "hidden",
        WebkitLineClamp: 3,
    },
    [`& .${classes.centerContent}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    [`& .${classes.barcode}`]: {
        fontFamily: "'Libre Barcode 39 Text'",
        fontSize: 48,
    },

    [`& .${classes.stickyFont}`]: {
        fontSize: 7,
        [`& p`]: {
            fontSize: 7,

        },
    },
}));

function createDynamicStyles() {
    const style = document.createElement('style');
    style.type = 'text/css';

    const styles = `
    @page {
        size: 5cm 4cm; 
        margin: 1mm !important;
        overflow: hidden;
    }
    * {
            color: black;
    }
    body {
            background: #fff !important
    }
    `;

    if (style.styleSheet) {
        style.styleSheet.cssText = styles;
    } else {
        style.appendChild(document.createTextNode(styles));
    }

    document.head.appendChild(style);
}

const Template5X4 = (props) => {
    const {
        loading,
        validData,
        parsedData,
        isBatch,
        currency,
        customer
    } = props

    const { t } = useTranslation()

    useEffect(() => {
        createDynamicStyles()

    }, [])

    return (
        <Fragment>
            {loading || (!loading && !validData) ? (
                <>
                    <EmptyTableMessage
                        loading={loading}
                        message={t("noShipmentWithCode")}
                    />
                </>
            ) : (
                parsedData.map((shipment, index) => (
                    <Root key={index}>
                        <Stack spacing={0.2} className={clsx({ [classes.printSticky]: !loading && validData, })}>
                            <Typography textAlign={"center"} className={classes.stickyFont}>اليكسندر للشحن</Typography>
                            <div
                                className={clsx(
                                    classes.barcode,
                                    classes.centerContent,
                                    classes.stickyCode
                                )}
                            >
                                *{shipment.code}*
                            </div>
                            <div className={clsx(classes.stickyRecipientName, classes.stickyFont)}>
                                - {shipment?.recipientName}
                            </div>
                            <div className={clsx(classes.stickyDestination, classes.stickyFont)}>
                                -{!isBatch && " " +
                                    shipment?.recipientZone?.name +
                                    " - " +
                                    shipment?.recipientSubzone?.name}
                            </div>
                            <Stack className={classes.stickyFont} direction={"row"} spacing={0.2}>
                                <Typography>
                                    {t("cod")}:
                                </Typography>
                                <Stack direction={"row"} spacing={0.3}>
                                    <Typography>
                                        {shipment?.totalAmount}
                                    </Typography>
                                    <Typography>
                                        {!isBatch && currency}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack className={classes.stickyFont} direction={"row"} spacing={0.2}>
                                <Typography>
                                    {t("senderName")}:
                                </Typography>
                                <Typography>{customer ? customer.name : shipment?.senderName}</Typography>
                            </Stack>
                            <Stack className={classes.stickyFont} direction={"row"} spacing={0.2}>
                                <Typography>
                                    {t("notes")}:
                                </Typography>
                                <Typography>{shipment?.notes}</Typography>
                            </Stack>
                        </Stack>
                    </Root>
                ))
            )}
        </Fragment>
    )
}

export default Template5X4